$searchform-gap: 1rem;

.searchform {
    margin-bottom: $spacing-y;

    @include mq-down(lg) {
        .btn--primary {
            min-height: 50px;
        }
    }

    @include mq(lg) {
        display: flex;
        margin: 0 (-$searchform-gap/2) 1rem;

        display: grid;
        grid-template-columns: 5fr 5fr 3fr 3fr auto;
        grid-column-gap: $searchform-gap;

        &__item {
            display: flex;
            flex: 1 0 150px;
            padding: 0 ($searchform-gap/2);

            &--query,
            &--custodian {
                flex-grow: 3;
            }
            &--submit {
                flex-grow: 0;
                flex-basis: auto;
            }
        }

        .field,
        .select {
            padding-top: 0rem;
            padding-bottom: 0rem;
            width: 100%;
            min-width: 100px;
        }
    }

    @supports (display: grid) {
        margin: 0 0 1rem !important;

        &__item {
            flex: none !important;
            padding: 0 !important;
        }
    }
}
