// Data View Layout

.view {
    display: flex;
    flex-direction: column;

    &__sidebar {
        margin-bottom: $spacing-y;

        &__title {
            @extend .sidebar__title;
        }
        &__inner {
            @extend .sidebar;
        }
        &__section {
            @extend .sidebar__section;
        }
    }
    &__content {
        @include padding-x;
        margin-bottom: $spacing-y;
    }

    @include mq(xl) {
        padding: $spacing-y 0;
        flex-direction: row;

        &__sidebar {
            flex-basis: 28%;
            max-width: 400px;
            margin: 0;
        }
        &__content {
            flex-basis: 72%;
            max-width: 1200px;
        }
    }
}
