.checkboxfilter {
    display: flex;
    align-items: flex-start;
    margin-top: 2rem;

    &__icon {
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        width: 52px;
        padding: 0.375em;
        font-size: 1.5em;
        margin-top: -0.25rem;
        margin-right: 1rem;
    }
    &__content {
        flex: 1 0 0%;
        font-size: 0.875rem; //14px
    }
}
