.chart {
    font-size: 0.75rem;

    &__legend {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        grid-column-gap: 10px;

        &__item {
            display: flex;
        }

        &__label {
            @include truncate;
            flex: 1 0 0%;
        }

        &__value {
            font-weight: bold;
            margin-right: 0.25rem;
            width: 5ch;
            text-align: right;
            flex-basis: 5ch;
        }

        &__bullet {
            display: block;
            flex-basis: 1em;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            margin-right: 0.25rem;
            background-color: currentColor;
            transform: translateY(30%);
        }

        @include mq(lg) {
            font-size: 0.6875rem;
        }
    }
    &__content {
        min-height: 300px;
    }
    &__controls {
        margin-bottom: 1rem;
    }
    &__filter {
        margin-right: 0.5rem;

        &__label {
            display: block;
            padding: 0.5rem 1rem;
            border-radius: 10em;
            line-height: 1;
            background-color: $gray-lighter;
            cursor: pointer;

            @include hover-focus {
                background-color: $gray-light;
            }

            input:checked + & {
                color: #fff;
                background-color: $brand-primary;
            }

            input:disabled + & {
                pointer-events: none;
                cursor: auto;
                opacity: 0.4;
            }
        }
    }
}

.chart--timeseries {
    .recharts-tooltip-item {
        font-weight: 700;
    }
    .recharts-tooltip-item-name,
    .recharts-tooltip-item-separator {
        display: none;
    }
}
