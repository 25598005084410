// ToolTip

.has-tooltip,
.has-tooltip-multiline {
    &[aria-label] {
        position: relative;

        &::after {
            display: block;
            content: attr(aria-label);
            width: auto;
            position: absolute;
            z-index: -1;
            bottom: 100%;
            left: 50%;
            padding: 0.5rem;
            font-size: 0.75rem;
            font-weight: normal;
            white-space: nowrap;
            line-height: 1;
            color: #fff;
            background-color: $gray-darkest;
            border-radius: 0.25rem;
            opacity: 0;
            transform: translate(-50%, 0);
            transition: all 0.2s ease;
            pointer-events: none;
            text-transform: none;
        }
        &:hover::after {
            display: block;
            opacity: 1;
            z-index: z('content-overlay');
            transform: translate(-50%, -0.25rem);
        }
    }
}

.has-tooltip-multiline[aria-label]::after {
    white-space: normal;
    width: 220px;
    line-height: 1.4;
}
