// Main Typography Styles

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-family: $font-family-brand;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.25;
}

h1,
.h1 {
    font-size: 2.5rem; //40px
}

h2,
.h2 {
    font-size: 2rem; //32px
}

h3,
.h3 {
    font-size: 1.3125rem; //21px
}

// Misc Minor Type Styles

.value-not-available {
    opacity: 0.5;
    user-select: none;
}
