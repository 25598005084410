// Select Inputs

// Native HTML
select {
    display: inline-block;
    padding: 4px;
    outline: 0 !important;
    background-color: #fff;
    border: 1px solid $field-border-color;
    height: 2rem;
}

.select {
    @extend %field;

    option {
        padding: 0;
    }

    // hide dropdown arrow in IE
    select::-ms-expand {
        display: none;
    }

    &:after {
        content: '';
        display: block;
        height: $field-font-size * 0.875;
        width: $field-font-size * 0.875;
        position: absolute;
        top: 50%;
        right: $field-padding;
        transform: translateY(-50%);
        background-color: transparent;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ4OCwgLTQyOCkiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQ4OCwgNDI5KSI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjE0IiBoZWlnaHQ9IjE0Ij48L3JlY3Q+PHBhdGggZD0iTTExLDUgTDcsOSBMMyw1IiBzdHJva2U9IiMzQzNDM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==');
        background-size: cover;
        pointer-events: none;
    }

    &__input {
        @extend %field__input;
        padding-right: 2rem; //space for dropdown arrow
        opacity: 0;
        border: 0;

        .is-dirty & {
            opacity: 1;
        }
    }
    &__inner {
        @extend %field__inner;
    }
    &__label {
        @extend %field__label;

        // just force the label to floating state,
        // since selects currently always have a default value

        // transform: translateY(-1em);
        // font-size: $field-font-size-small;
        // transition-duration: 0s;
    }
    &__hint {
        @extend %field__hint;
    }
    &__error {
        @extend %field__error;
        position: absolute;
        bottom: 100%;
        left: -1px;
        right: -1px;
        border-radius: $border-radius $border-radius 0 0;
        transform: translateY(1px);
    }
}
