.responsiveimage {
    @include coverall;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &--empty {
        background-color: $gray-light;
    }

    &__img {
        @include coverall;
        width: 100%;
        height: 100%;
        object-fit: cover;

        // on IE, these are divs with background-image style
        &--fallback {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}
