.accordion {
    &__target {
        position: relative;
    }
    &__header {
        @include button-reset;
        display: block;
        width: 100%;
        font-size: 1.25rem;
        padding: 1rem 3rem 1rem 2rem;
        text-align: left;
        position: relative;
        border-top: 2px solid $brand-primary;
    }
    &__panel {
        max-height: 75vh;
        overflow: hidden;
        margin-bottom: 0;

        &__inner {
            padding-bottom: 1rem;

            > :last-child {
                margin-bottom: 0;
            }
        }

        &.is-hidden {
            max-height: 0 !important;
        }

        // transition is added via `accordion--initalised` to stop animation on initalition
        .badger-accordion--initialized & {
            transition: max-height ease-in-out 0.2s;
        }
    }
    &__indicator {
        color: $brand-primary;
        position: absolute;
        top: 0.75rem;
        right: 0;
        font-size: 1rem;

        .icon {
            transform: rotate(0);
            transition: transform ease 0.2s;

            .-ba-is-active & {
                transform: rotate(180deg);
            }
        }
    }
    &__link {
        position: absolute;
        top: 1.2rem;
        left: 0;
        color: $gray-light;

        @include hover-focus {
            color: $brand-primary;
        }
    }
}
