.searchresults {
    &__controls {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }
    &__content {
        // empty
    }
    &__more {
        position: relative;
        text-align: center;
    }
    &__count {
        margin: 0;
    }
    &__item {
        margin-bottom: $spacing-y;
    }
    &__ordering {
        &__label {
            margin-right: 0.5rem;
        }
    }

    @include mq(md) {
        &__ordering {
            order: -1;
        }
    }
}
