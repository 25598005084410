// Card for Criterion Pages

.criterion-card {
    @include card;
    @include card-hover;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    text-align: left;
    position: relative;

    &__title {
        @include hyphenate;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }
    &__icon {
        display: block;
        border: 2px solid $brand-primary;
        border-radius: 50%;
        padding: 0.5rem;
        font-size: 1.5rem;
        margin-right: 0.75rem;
        color: $body-color;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 auto;
        padding: 1rem;

        p {
            width: 100%;
            margin: 0;
        }
    }
    &__readmore {
        display: inline-flex;
        padding: 0.5rem 0.5rem 0.5rem 0;
        position: relative;
        top: 0.5rem;
        z-index: 2;
        margin-top: auto;

        @include hover-focus {
            text-decoration: underline;
        }

        .icon {
            margin-right: 0.25rem;
        }
    }
    &__image {
        position: relative;
        padding-bottom: 56%;
    }
    &__link::after {
        @include coverall;
        content: '';
        z-index: 1;
        pointer-events: auto;
    }
}
