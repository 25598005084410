.subheader {
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &__inner {
        @include padding-x;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__left {
        flex: 1 0 0%;
        margin-bottom: 1rem;
    }

    &__right {
        flex: 0 0 auto;

        .btn {
            margin: 0.5rem 1rem 0 0;
        }
    }

    &__title {
        font-size: 1.25rem;
    }

    &__subtitle {
        color: $gray-dark;
    }
    &__backlink {
        display: flex;
        align-items: center;
    }
    &__grouplink {
        color: $body-color;
        text-decoration: underline;

        @include hover-focus {
            color: $body-color;
            text-decoration: none;
        }
    }

    @include mq(lg) {
        &__inner {
            display: flex;
            align-items: center;
        }
        &__left {
            padding: 0 $spacing-x 0 0;
            margin: 0;
            display: flex;
            align-items: flex-start;
        }
        &__right .btn {
            margin: 0 0 0 1rem;
        }
        &__backlink {
            flex: 0 0 3rem;
            justify-content: center;
            order: -1;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin: 0 1rem 0 -0.5rem;
            background-color: rgba($brand-primary, 0.1);

            @include hover-focus {
                background-color: rgba($brand-primary, 1);
                color: #fff;
            }

            &__text {
                display: none;
            }
        }
        &__subtitle {
            margin: 0;
        }
    }
}
