.quicksearch {
    display: flex;
    border: 1px solid $brand-primary;
    width: 100%;
    max-width: 400px;

    &__input {
        flex: 1 0 0%;
        border: 0;
        color: $body-color;
        padding: 0 1rem;
        min-width: 100px;

        &:focus {
            outline: 0;
        }
    }
    &__submit {
        @include button-reset;
        @include flex-center;
        padding: 0.5rem;
        color: #fff;
        background-color: $brand-primary;
    }
}

// Variation for Mobile Version in Header
.header .quicksearch {
    border: 0;

    &__submit {
        order: -1;
        padding: 1rem;
        color: $brand-primary;
        background-color: transparent;
    }
}
