.fund-statuslist {
    @include scrollable;
    max-height: 320px;

    &__item {
        display: flex;
        padding: 0.5rem 1rem;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-lighter;
        }

        &--positive {
            color: $brand-green;
        }
        &--negative {
            color: $brand-red;
        }
        &--medium {
            color: $brand-yellow;
        }
        &--neutral {
            color: $gray-light;
        }
    }
    &__icon {
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border: 2px solid;
        border-radius: 50%;
        padding: 0.375em;
        margin-top: 0.25rem;
        font-size: 1rem;
        margin-right: 1rem;

        .icon {
            color: $body-color;
        }
    }
    &__message {
        flex: 1 0 0%;
        margin: 0;
        max-width: 40ch;
        color: $body-color;
    }
    &__empty {
        padding: 0.5rem 1rem;
    }
}
