.cookiebanner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 1rem 0;
    background-color: white;
    box-shadow: 0 -2px 24px rgba(0, 0, 0, 0.15);

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__text {
        flex-basis: 100%;

        a {
            text-decoration: underline;
            @include hover-focus {
                text-decoration: none;
            }
        }

        @include mq(md) {
            flex: 1 0 0%;
            margin: 0;
            padding-right: 1rem;
        }
    }

    .btn + .btn {
        display: block;
        margin-left: 1rem;
    }
}
