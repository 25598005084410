// Fund Widget Component

$fund-cell-border-color: $gray-light;
$fund-cell-border-width: 1px;

// Fund Widgets need to change their layout at different breakpoints.
// They (currently) only occur in a context where they don't take up the
// whole viewport width at desktops, so we'd really need a container query here.
// unfortunately these don't exist - so media queries have to take context into account.

.fund {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background-color: #fff;
    font-size: 0.875rem; // 14px

    // Main Layout
    &__header,
    &__criteria,
    &__actions {
        flex-basis: 100%;
        max-width: 100%;
    }
    &__sustainability,
    &__performance {
        flex-basis: 50%;
        max-width: 50%;
    }
    @media screen and (min-width: 800px), print {
        &__header {
            flex-basis: 48%;
            max-width: 48%;
        }
        &__sustainability,
        &__performance {
            flex-basis: 26%;
            max-width: 26%;
        }
    }

    // Individual Sections
    &__header {
        display: flex;
        flex-direction: column;
        background-color: darken($gray-lightest, 2%);
    }

    &__sustainability,
    &__performance {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        text-align: center;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        padding: 0 !important;
        background-color: darken($gray-lightest, 2%);

        &__spacer {
            // this is necessary because of a bug in IE11.
            // it wont calculate the space correctly when
            // margin-auto is used with justify-content.
            // so this spacer fills the area.
            // @link https://stackoverflow.com/a/37535548/9293736
            @include mq(md) {
                flex: 1 0 0%;
            }
        }

        .btn {
            flex: 1 0 50%;
            font-size: 1em;
            padding: 1em;

            @include mq(md) {
                flex: none;
            }
        }
        .btn:not(.btn--primary) {
            background-color: transparent;
            color: $brand-primary;
            font-weight: normal;

            .btn + & {
                border-left: 1px solid $gray-lighter;
            }

            @include hover-focus {
                background-color: rgba($gray, 0.15);
            }
        }
        .btn--criteria-toggle {
            border: 0 !important;

            @include mq-down(md) {
                display: none;
            }
        }
    }

    // Padding and Borders
    &__cell {
        padding: 1em;
        margin: 0;
        // this really weird box-shadow mimics the border-collapse behaviour
        // of tables, so we display a 1px border between cells, regardless
        // of the widget's current layout.
        box-shadow: $fund-cell-border-width 0 0 0 $fund-cell-border-color,
            0 $fund-cell-border-width 0 0 $fund-cell-border-color,
            $fund-cell-border-width $fund-cell-border-width 0 0
                $fund-cell-border-color,
            $fund-cell-border-width 0 0 0 $fund-cell-border-color inset,
            0 $fund-cell-border-width 0 0 $fund-cell-border-color inset;
    }
    // Animated Toggleable Panel
    &__togglepanel {
        width: 100%;
        flex-basis: 100%;
    }

    // Presentational
    &__title {
        flex: 1 0 auto;
    }
    &__name {
        @include hyphenate;
        font-size: 1.1428em; // 16px
        margin-bottom: 0.25em;
    }
    &__isin {
        margin: 0;
    }
    &__grouplink {
        display: block;
        color: $body-color;
        text-decoration: underline;
        margin-top: 0.25rem;

        @include hover-focus {
            color: $body-color;
            text-decoration: none;
        }
    }
    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 0.5rem;
    }
    &__label {
        @include hyphenate;
        font-size: 1em;
    }
    &__chart {
        @include flex-center;
        flex: 1 0 auto;
        font-size: 1.1428em; // 16px
        padding: 0.5em 0;
        font-weight: 700;
    }
    &__trend {
        @include flex-center;
        width: 80px;
        height: 80px;
        position: relative;

        &__value {
            position: relative;
            text-shadow: 0 0 4px white;

            &--positive {
                color: inherit;
            }
            &--negative {
                color: $brand-red;
            }
        }

        .icon {
            color: $gray-lighter;
            width: 120%;
            height: 120%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &__badge {
        @include truncate;
        @include flex-center;
        text-transform: uppercase;
        font-weight: bold;

        .icon {
            font-size: 2em;
            margin-right: 0.125em;
        }

        &--gold .icon {
            fill: url('#svg-gradient-gold');
        }
        &--silver .icon {
            fill: url('#svg-gradient-silver');
        }
        &--bronze .icon {
            fill: url('#svg-gradient-bronze');
        }
    }
}

// HTML-Only Version
.fund[data-html] {
    .fund__togglepanel {
        height: 0;
        max-height: 0;
        transition: max-height ease-in-out 0.25s;
        overflow: hidden;
    }
    &.fund--with-criteria .fund__togglepanel {
        height: auto;
        max-height: 300px;
    }
    &.fund--with-criteria.is-open .fund__togglepanel {
        overflow: visible;
    }
}

// Print Styles

@media print {
    .fund {
        font-size: 0.75rem; //12px
        border: 1px solid $gray-lighter;
        page-break-inside: avoid;
        break-inside: avoid;

        &__chart {
            font-size: 1em;
        }

        &__trend {
            width: 60px;
            height: 60px;
        }

        &__togglepanel {
            height: auto !important;
            max-height: none !important;
            overflow: visible !important;

            &__inner {
                display: block !important;
            }
        }

        &__actions {
            display: none;
        }
    }
}
