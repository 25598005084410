.nav {
    position: relative;
    z-index: 1;
    font-size: 0.875rem;

    &__menu {
        display: flex;
        align-items: stretch;
    }

    &__submenu {
        display: none;
        background-color: $brand-blue-dark;
        // IE11 bug: ie doesn't apply styling to nested lists if they are not visible on page load.
        // this hack fixes it. @see https://github.com/tjvantoll/jquery-ui/commit/6acd1c8640b271db7df03f1457a817d5d6a1f29d
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    }

    &__item {
        position: relative;
    }

    &__link {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1.25rem 1rem;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        white-space: nowrap;

        @include hover-focus {
            color: #fff;
            background-color: rgba($brand-primary, 0.1);
        }

        .nav__submenu & {
            text-transform: none;
            letter-spacing: 0;
        }

        .icon {
            margin-right: 0.25em;
        }
    }

    // MOBILE VARIANT

    @include mq-down(xl) {
        &__content {
            @include scrollable;
            display: flex;
            flex-direction: column;
            visibility: hidden;
            position: fixed;
            z-index: z('navigation');
            width: 300px;
            top: $header-height;
            right: 0;
            bottom: 0;
            background-color: $brand-blue-dark;
            transform: translateX(110%);
            transition: transform 0.3s $animation-curve-default;
        }
        &__menu {
            flex-direction: column;
        }
        &__item {
            border-bottom: 1px solid rgba(#fff, 0.08);

            &--last {
                border-bottom-color: $brand-primary;
            }
        }
        &__togglebtn {
            @include button-reset;
            @include flex-center;
            padding: 1rem;
        }

        &--open {
            // OPEN STATE
            .nav__content {
                visibility: visible;
                transform: translateX(0);
            }
        }
    }

    // DESKTOP VARIANT

    @include mq(xl) {
        &__menu {
            height: $header-height;
        }
        &__submenu {
            position: absolute;
            top: 100%;
            min-width: 200px;

            .nav__link {
                padding: 0.75rem 1rem;
            }
        }
        &__item--last {
            border-right: 1px solid $brand-primary;
        }
        &__item--with-submenu {
            > .nav__link::after {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 4px;
                vertical-align: middle;
                border-top: 4px solid;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }
            &:hover > .nav__submenu {
                display: block;
            }
        }
        &__togglebtn {
            display: none;
        }
    }

    // EXTRA WIDE VARIANT

    @media (min-width: 1400px) {
        &__link {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
}
