// Font Face Declarations
@font-face {
    font-family: 'Montserrat';
    src: url('/static/fonts/montserrat/montserrat-400.woff2') format('woff2'),
        url('/static/fonts/montserrat/montserrat-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/static/fonts/montserrat/montserrat-700.woff2') format('woff2'),
        url('/static/fonts/montserrat/montserrat-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
