.modal {
    z-index: z('modal');
    background-color: #fff;
    padding: 2rem;
    width: 90%;
    max-width: 550px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);

    &__title {
        margin-bottom: 1rem;
        text-align: center;
    }

    &__overlay {
        position: fixed;
        z-index: z('modal') - 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &__close {
        @include button-reset;
        position: absolute;
        top: 1rem;
        right: 1rem;

        @include hover-focus {
            color: $brand-primary;
        }
    }

    &__scrollpanel {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        max-height: 200px;
        padding: 1rem;
        border: 1px solid $gray-light;
        margin-bottom: 1rem;

        @media screen and (min-height: 800px) {
            max-height: 280px;
        }
    }

    &__actions .btn {
        white-space: normal;
    }

    &__footnote {
        padding-top: 1rem;
        line-height: 1.2;
    }
}
