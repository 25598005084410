// Table Component

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    border: 1px solid $gray-light;

    tbody {
        border: 1px solid $gray-light;
    }

    tr {
        background-color: #fff;
    }

    td,
    th {
        @include hyphenate;
        padding: 0.25rem 0.5rem;
        border-bottom: 1px solid $gray-light;
        border-left: 1px solid $gray-light;
        vertical-align: middle;
    }

    td {
        font-variant-numeric: tabular-nums;
    }

    th {
        font-size: 0.875rem;
        font-family: $font-family-brand;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-darker;
    }

    thead th {
        padding-bottom: 0.5rem;
    }

    tbody tr:nth-of-type(odd) {
        background-color: $gray-lightest;
    }

    code {
        white-space: nowrap;
    }

    &__col {
        &--alignright {
            text-align: right;
        }
    }
}

.table--alignright {
    td,
    th {
        text-align: right;
    }
    th[scope='row'] {
        text-align: left;
    }
}

.table--responsive {
    @media screen and (max-width: 640px) {
        border: 0;

        thead {
            display: none;
        }
        tbody {
            border: 0;
        }

        tr,
        td {
            display: block;
            text-align: left;
        }

        tr {
            border-top: 1px solid $gray-light;
            + tr {
                margin-top: 2rem;
            }
        }

        th[scope='row'] {
            display: block;
            width: 100%;
            border-right: 1px solid $gray-light;
        }

        td {
            display: flex;
            align-items: center;
            border-left: 0;
        }

        td[label]::before {
            @include truncate;
            display: inline-block;
            content: attr(label);
            font-weight: 500;
            flex: 0 0 45%;
            max-width: 45%;
        }
    }
}
