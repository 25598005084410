// Dashboard Grid Layout

$dashboard-gap: $spacing-x;

.dashboard {
    display: flex;
    flex-wrap: wrap;
    margin: -$dashboard-gap/2;

    display: grid;
    grid-gap: $dashboard-gap;
    grid-template-columns: 1fr;

    &__item {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        padding: $dashboard-gap/2;

        &--full {
            grid-column: 1 / -1;
        }
    }

    @include mq(md) {
        grid-template-columns: 1fr 1fr;

        &__item {
            flex-basis: 50%;
            max-width: 50%;

            &--full {
                flex-basis: 100%;
                max-width: none;
            }
        }
    }

    @supports (display: grid) {
        margin: 0;

        &__item {
            padding: 0;
            flex: none !important;
            max-width: none !important;
        }
    }
}
