.account {
    &__header {
        margin-bottom: $spacing-y;
    }
    &__title {
        font-size: 2rem;
    }

    @include mq(md) {
        &__header {
            display: flex;
            align-items: center;

            &__main {
                flex: 1 0 0%;
            }
            &__additional {
                flex-basis: auto;
            }
        }
        &__subtitle {
            margin: 0;
        }
    }
}
