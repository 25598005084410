// Styles for Content generated through Streamfield Blocks

.streamfield {
    text-align: left;
    font-size: 1rem; //16px
    font-weight: 400;
    line-height: 1.5;

    @include mq(md) {
        font-size: 1.125rem; //18px
    }

    // ============= ELEMENT STYLES ==============
    // css for tags the post editor can generate

    h2,
    h3,
    h4 {
        margin-top: 1.5em;
        margin-bottom: 0.75em;

        &:first-child {
            margin-top: 0;
        }
    }

    a {
        @include hyphenate;
        text-decoration: underline;

        @include hover-focus {
            text-decoration: none;
        }
    }

    p {
        margin-bottom: 1.5em;
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        vertical-align: bottom;
    }

    ul,
    ol {
        padding-left: 0;
        margin-bottom: 1.5em;
        list-style-position: inside;

        li {
            padding: 0.25rem 0;
        }
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    table {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    // buzz has all kinds of weird inline styles and markup around blockquotes,
    // so this has to be very explicit to override some of that.
    blockquote {
        margin: 2em 0;
        padding-left: 1em;
        border-left: 10px solid $brand-primary;
        text-align: left;
        font-style: italic;

        > p {
            text-align: left !important;
        }

        b {
            font-weight: inherit !important;
        }
    }

    // ============= BLOCK STYLES ==============
    // these are content blocks defined by wagtail

    .block-paragraph {
        // this is intentionally left blank;
        // b/c imports may cause empty blocks
    }

    .block-image,
    .block-video,
    .block-embed {
        margin: 2em 0;
        overflow: hidden;
        text-align: center;
    }

    .block-audio {
        margin: 2em 0;

        audio {
            display: block;
            width: 100%;
        }
    }

    .responsive-object {
        position: relative;
        overflow: hidden;

        &--16-9 {
            padding-top: 56.25% !important;
        }

        video,
        iframe {
            @include coverall;
            width: 100%;
            height: 100%;
        }
    }

    // ============= DAMAGE CONTROL ==============
    // try to work around some of the weird post markup editors sometimes produce.

    // hide empty elements
    p:empty,
    h2:empty,
    h3:empty,
    ul:empty,
    ol:empty,
    blockquote:empty {
        display: none;
    }

    // reset spacing when the first rich text block starts with a headline
    .block-paragraph:first-child {
        h2:first-child,
        h3:first-child {
            margin-top: 0;
        }
    }

    // center embedded tweets
    .twitter-tweet-rendered {
        margin-left: auto;
        margin-right: auto;
    }
}
