// Default Page

.page {
    &__header {
        position: relative;
        padding: $spacing-y 0;

        &__inner {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 5;

            @include mq(lg) {
                justify-content: center;
                align-items: center;
            }
        }

        &--with-bg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 299px; // IE11 vertical centering bug
            min-height: 300px;
            margin-bottom: $spacing-y;
            color: #fff;
            text-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.45);

            &::after {
                @include coverall;
                content: '';
                display: block;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.25);
            }

            @include mq(md) {
                height: 429px;
                min-height: 430px;
            }
        }
    }
    &__title {
        @include hyphenate;
        text-align: center;
    }

    &__icon {
        @include flex-center;
        width: 2em;
        height: 2em;
        color: $body-color;
        background-color: #fff;
        border-radius: 50%;
        padding: 0.25em;
        font-size: 1.5rem;
        flex: none;
    }

    &__content {
        margin-bottom: $spacing-y;
    }

    &__body {
        max-width: 48rem; //768px;
        margin: 0 auto $spacing-y;

        @include mq(md) {
            @include card();
            padding: $spacing-y $spacing-x;
        }
    }
}

// Criterion Pages
.page--criterion {
    .page__icon {
        order: 1;
        margin-left: 1rem;
    }
    .page__logo {
        display: block;
        width: 50px;
        height: 50px;
        object-fit: contain;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid rgba($gray-dark, 0.5);
    }
    .page__title {
        text-align: left;
    }
    .page__description {
        display: flex;
        align-items: center;
        text-align: left;

        p {
            margin: 0;
            max-width: 18em; //288px
        }
    }

    @include mq(lg) {
        .page__header__inner {
            flex-direction: row;
            align-items: center;
        }
        .page__title {
            text-align: right;
            padding-right: 3rem;
        }
        .page__description {
            padding: 3rem 0 3rem 3rem;
            border-left: 2px solid;
        }
        .page__icon {
            order: -1;
            font-size: 2rem;
            margin: 0 1rem 0 0;
        }
        .page__logo {
            width: 100px;
            height: 100px;
        }
    }
}

// Partner Pages
.page--partner {
    .page__header {
        text-align: center;
    }
}

// Error Pages
.page--error {
    padding: $spacing-y 0;

    .page__title {
        margin-bottom: 1rem;
    }
    .page__content {
        font-size: 1.125rem;
        margin: 0 auto;

        p:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    @include mq(md) {
        padding: ($spacing-y * 3) 0;
        text-align: center;

        .page__content {
            max-width: 500px;
        }
    }
}
