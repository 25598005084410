// Main Site Layout

body {
    overflow-x: hidden;
    height: 100%;

    &.no-scroll {
        overflow: hidden;
    }
}

// Default Layout

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: 100vh;
    position: relative;

    .header,
    .footer {
        flex: none;
    }

    .main {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        .layout--centered {
            justify-content: center;
        }

        &::after {
            content: '';
            display: block;
            height: 0px;
            visibility: hidden;
        }
    }
}
