$criterionlist-gap: $spacing-x;
$criterionlist-gap-compact: 1rem;

.criterionlist {
    font-size: 0.875em;

    display: flex;
    flex-wrap: wrap;
    margin: -$criterionlist-gap/2;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: $criterionlist-gap;

    &__item {
        flex: 1 0 250px;
        min-width: 250px;
        padding: $criterionlist-gap/2;
    }

    &--compact {
        grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
        grid-gap: $criterionlist-gap-compact;
        margin: -$criterionlist-gap-compact/2;

        @media screen and (min-width: 600px), print {
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (min-width: 1270px) {
            grid-template-columns: repeat(5, 1fr);
        }

        .criterionlist__item {
            flex: 1 0 100px;
            min-width: 100px;
            padding: $criterionlist-gap-compact/2;

            @include mq(md) {
                flex: 0 0 33%;
                max-width: 33%;
            }
            @include mq(lg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }

    @supports (display: grid) {
        margin: 0 !important;

        &__item {
            padding: 0 !important;
            flex: none !important;
            min-width: non !important;
            max-width: none !important;
        }
    }
}
