.partner-card {
    @include card;
    @include card-hover;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    text-align: center;

    &__link {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        color: inherit;

        @include hover-focus {
            color: inherit;
        }
    }
    &__title {
        @include flex-center;
        @include hyphenate;
        font-size: 1rem;
        flex: 1 0 auto;
        padding: 1rem 2rem;
        border-top: 1px solid $brand-primary;
    }
    &__image {
        @include flex-center;
        flex-basis: 250px;
        height: 250px;
        order: -1;
        padding: 2rem;
    }
    &__logo {
        display: block;
        flex: 0 0 auto;
        width: 100%;
        max-width: 200px;
        height: 130px;
        object-fit: contain;
    }
}
