.react-autosuggest {
    &__container {
        position: relative;

        .field & {
            position: static;
        }
    }

    &__suggestions-container {
        @include scrollable;
        display: none;
        position: absolute;
        top: 100%;
        min-width: 250px;
        max-height: 300px;
        margin: 0;
        padding: 0;
        border: 1px solid $gray-light;
        background-color: #fff;
        box-shadow: $box-shadow-elevated;
        font-size: 1rem;
        z-index: 2;

        &--open {
            display: block;
        }
    }

    &__suggestion {
        cursor: pointer;
        padding: 0.5rem 1.25rem;

        &:hover,
        &--highlighted {
            color: #fff;
            background-color: $brand-primary;
        }

        &__label {
            display: flex;
            align-items: center;
        }
    }
}
