.divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $spacing-y * 2 0;

    &::before,
    &::after {
        content: '';
        display: block;
        flex: 1 0 1rem;
        height: 1px;
        background-color: $gray;
    }

    &__label {
        display: flex;
        align-items: center;
        font-family: $font-family-brand;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.25;
        text-align: center;
        padding: 0 $spacing-x;
        margin: 0;

        .icon {
            flex: none;
            margin-right: 0.5rem;
            color: $brand-primary;
        }
    }
}
