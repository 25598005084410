// Page Sections

.section {
    text-align: center;
    padding-top: $spacing-y;
    padding-bottom: $spacing-y;

    &__title {
        margin-bottom: $spacing-y;
    }

    @include mq(md) {
        &__bracket {
            position: relative;
            height: 2rem;
            border: 2px solid $brand-primary;
            border-bottom: 0;
            margin: 3rem 4rem 2rem;

            &::before {
                content: '';
                display: block;
                width: 2px;
                height: 2rem;
                background-color: $brand-primary;
                position: absolute;
                top: -2rem;
                left: 50%;
                margin-left: -1px;
            }
        }
    }
}
