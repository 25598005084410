.criterionfilter {
    display: flex;
    align-items: flex-start;
    padding: 1rem 0;
    font-size: 0.9375rem;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(#fff, 0.125);
    }

    &__title {
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
    }
    &__options {
        display: flex;
        font-size: 0.875rem; //14px
    }
    &__option:not(:last-child) {
        margin-right: 0.5rem;
    }
    &__label {
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem 0.25rem 0;
        margin: 0;
        cursor: pointer;
        color: $gray;

        @include hover-focus {
            color: $gray-lighter;
        }
    }
    &__text {
        input:checked ~ & {
            color: #fff;
        }
    }
    &__indicator {
        display: block;
        width: 1.5em;
        height: 1.5em;
        border: 1px solid $brand-primary;
        border-radius: 50%;
        margin-right: 0.5em;
        position: relative;

        input:checked ~ & {
            background-color: $brand-primary;
            border-color: $brand-primary;

            &::after {
                content: '';
                display: block;
                width: 0.5em;
                height: 0.5em;
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__icon {
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        border: 1px solid $brand-primary;
        border-radius: 50%;
        padding: 0.375em;
        margin-top: 0.125rem;
        font-size: 1.5em;
        margin-right: 1rem;
    }
    &__content {
        flex: 1 0 0%;
    }
}
