// Header Component

.header {
    color: #fff;
    background-color: $brand-blue-dark;
    position: relative;
    z-index: z('header');

    // faded border
    &::after {
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $brand-primary-gradient;
    }

    &__inner {
        @include padding-x;
        display: flex;
        align-items: center;
        height: $header-height;
    }
    &__right {
        margin-left: auto;
        margin-right: -1rem;
        display: flex;
        align-items: center;

        @include mq(md) {
            margin-right: -$spacing-x;
        }
    }
    &__brand {
        display: block;
        width: 10rem;

        img {
            width: 100%;
            height: auto;
        }

        @include mq(md) {
            width: 13rem;
        }
    }
    &__btn {
        @include button-reset;
        @include flex-center;
        padding: 1rem;
        color: inherit;

        @include hover-focus {
            color: inherit;
        }

        &--search {
            order: -1;

            @include mq(xl) {
                display: none;
            }
        }
        &--search-close {
            color: $gray-dark;
        }
    }

    &__search {
        @include coverall;
        z-index: 5;
        display: flex;
        background-color: #fff;
        box-shadow: $box-shadow;
    }

    &__searchform {
        display: flex;
        flex: 1 0 0%;
    }
}
