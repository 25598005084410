.postlist {
    padding: $spacing-y 0;

    &__item:not(:last-child) {
        margin-bottom: $spacing-y;
    }
    &__link {
        display: flex;
        flex-direction: column;
        color: $body-color;

        @include hover-focus {
            color: $body-color;
        }
    }
    &__image {
        position: relative;
        padding-bottom: 56%;
        border: 1px solid $brand-primary;
        margin-bottom: 1rem;
    }
    &__meta {
        color: $gray-dark;
        margin-bottom: 1rem;
    }
    &__excerpt {
        max-width: 32rem; // 512px
        margin-bottom: 1rem;
    }
    &__more {
        font-weight: 500;
        font-size: 1rem;
    }

    @include mq(md) {
        &__link {
            flex-direction: row;
            align-items: flex-start;
        }
        &__image {
            flex-basis: 33%;
            padding-bottom: 33%;
            margin: 0;
        }
        &__content {
            flex-basis: 66%;
            padding-left: $spacing-x;
        }
    }
    @include mq(lg) {
        &__image {
            padding-bottom: 20%;
        }
    }
}
