.sidebar {
    color: #fff;
    background-color: $brand-blue-dark;

    &__title {
        @include padding-x;
        font-size: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: $brand-primary-gradient;
    }
    &__section {
        @include padding-x;
        padding-top: $spacing-y;
        padding-bottom: $spacing-y;
    }
}
