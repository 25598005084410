// Content Box Component

.box {
    @include card;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    &__title {
        @include hyphenate;
        display: flex;
        align-items: center;
        flex: 1 0 0%;
        font-size: 1rem;
    }
    &__subtitle {
        margin: 0;
    }
    &__header {
        //display: flex;
        position: relative;
        padding: 1rem;

        &--withborder {
            border-bottom: 1px solid $gray-lighter;
        }
    }
    &__content {
        flex: 1 0 auto;
        position: relative;
        min-height: 100px;
        padding: 1rem;
        padding-top: 0;

        &--centered {
            @include flex-center;
            text-align: center;
        }
    }
}
