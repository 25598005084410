.fund-overview {
    display: flex;
    flex-wrap: wrap;

    &__stats,
    &__detail,
    &__risk {
        flex-basis: 100%;
        padding: 1rem;
        border-top: 1px solid $gray-lighter;
    }

    &__stats {
        &__item {
            position: relative;
            padding-left: 2.125rem;
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
        &__title {
            display: block;
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 700;
            padding-top: 0.2em;
        }
        &__icon {
            position: absolute;
            left: 0;
            top: 0;
            color: $brand-primary;
        }
    }

    @include mq(md) {
        &__stats {
            flex-basis: 33.3%;
            max-width: 33.3%;
            border-right: 1px solid $gray-lighter;
        }
        &__detail {
            flex-basis: 66.6%;
            max-width: 66.6%;
        }
    }
    @media (min-width: 1400px) {
        &__stats {
            flex-basis: 25%;
            max-width: 25%;
        }
        &__detail {
            flex-basis: 75%;
            max-width: 75%;
        }
    }
}
