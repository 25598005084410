// Button Component

.btn {
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 2px solid transparent;
    font-size: 0.875rem; //14px
    font-weight: bold;
    padding: 0.28571em 1em;
    text-decoration: none;
    -webkit-appearance: none;

    .icon {
        margin-right: 0.25em;
    }

    &:disabled,
    &--disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.65;
    }

    &--icon-right .icon {
        margin-right: 0;
        margin-left: 0.25em;
    }
}

.btn--large {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
}
.btn--xlarge {
    font-size: 1rem;
    padding: 1rem 2rem;
}

// Block Modifier for full-width
.btn--block {
    display: flex;
    width: 100%;

    & + & {
        margin-top: 1rem;
    }
}

// Primary CTA Buttons
.btn--primary {
    text-transform: uppercase;
    color: #fff;
    background-color: $brand-primary;
    transition: color 0.25s linear, background-color 0.25s linear;

    @include hover-focus {
        color: #fff;
        background-color: $brand-primary-dark;
    }
}

.btn--secondary {
    color: $brand-primary;
    border-color: $brand-primary;
    background-color: transparent;
    transition: color 0.25s linear, background-color 0.25s linear;

    @include hover-focus {
        color: $brand-primary-dark;
        border-color: $brand-primary-dark;
        background-color: rgba($brand-primary, 0.15);
    }
}

.btn--link {
    @include button-reset;
    display: inline;
    font-weight: 400;
    font-size: 1em;
    line-height: inherit;
    vertical-align: inherit;
    user-select: inherit;
    color: $link-color;

    @include hover-focus {
        text-decoration: underline;
    }
}

// Stateful Buttons

.btn {
    &__state {
        align-items: center;

        &--initial {
            display: flex;

            .btn[aria-expanded='true'] & {
                display: none;
            }
        }
        &--active {
            display: none;

            .btn[aria-expanded='true'] & {
                display: flex;
            }
        }
    }
}
