.footer {
    padding: $spacing-y 0;
    text-align: center;
    color: $gray-light;
    background-color: $brand-blue-dark;

    &__inner {
        @include padding-x;
        p {
            margin: 0;
        }
    }

    &__nav {
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;

        &__item {
            display: flex;
            align-items: center;
            padding: 0.25rem 0;
        }

        &__item:not(:last-child) {
            &::after {
                content: '\00b7';
                display: inline-block;
                padding: 0 0.5rem;

                @include mq(lg) {
                    padding: 0 1rem;
                }
            }
        }

        &__link {
            color: rgba(#fff, 0.75);
            text-transform: uppercase;
            letter-spacing: 1px;

            @include hover-focus {
                color: $brand-primary;
            }
        }
    }

    &__logo {
        margin: $spacing-y 0;
    }

    &__links {
        font-size: 0.875rem;
        color: $gray;

        a {
            color: inherit;
        }
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            margin: 0 0.5rem;
            border-radius: 50%;
            color: $brand-blue-dark;
            background-color: rgba(#fff, 0.75);

            @include hover-focus {
                background-color: #fff;
            }
        }
    }
}
