// Layout Component for Card Lists

$cardlist-gap: $spacing-x;

.cardlist {
    display: flex;
    flex-wrap: wrap;
    margin: -$cardlist-gap/2;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: $cardlist-gap;

    &__item {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        padding: $cardlist-gap/2;

        @include mq(md) {
            flex-basis: 50%;
            max-width: 50%;
        }

        @include mq(lg) {
            flex-basis: 33%;
            max-width: 33%;
        }
    }

    @supports (display: grid) {
        margin: 0;
        &__item {
            padding: 0;
            flex: none !important;
            max-width: none !important;
        }
    }

    &--partners {
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

        .cardlist__item {
            @include mq(lg) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }
}
