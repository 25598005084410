// Checkbox Group

.checkboxgroup {
    &__title {
        display: inline-block;
        width: auto;
        position: static;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;

        &.has-tooltip:hover,
        &.has-tooltip-multiline:hover {
            cursor: default;
        }
    }
    &__options {
        display: flex;
        flex-wrap: wrap;
    }
    &__option {
        flex-basis: 100%;

        .checkbox {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
    &__infolink {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .icon {
            margin-right: 0.25em;
        }
    }
}
