.criterion {
    display: flex;
    align-items: center;

    &.has-tooltip {
        &::after {
            bottom: 75%;
        }
        &:hover {
            cursor: help;
        }
    }

    &__icon {
        @include flex-center;
        @include traffic-light-states('.criterion', border-color);
        flex: none;
        font-size: 1.75em;
        width: 2.47619em;
        height: 2.47619em;
        border: 2px solid $brand-primary;
        border-radius: 50%;
    }
    &__content {
        flex: 1 0 0%;
    }
    &__title {
        margin: 0 0 0 0.7em;
        font-weight: 700;
        text-transform: uppercase;
    }
    &__value {
        @include traffic-light-states('.criterion', color);
        height: 1rem;
        border: 2px solid currentColor;
        border-left-width: 0;
        margin: 0.25em 0 0.25em -1px;

        &__bar {
            height: 100%;
            background-color: currentColor !important;
            -webkit-print-color-adjust: exact;
        }
    }
    &__link {
        display: block;
        margin-left: 0.7em;
        color: inherit;
        text-decoration: underline;
        @include hover-focus {
            color: $brand-cyan;
        }
    }
    &__detail {
        @include hyphenate;
        margin-left: 0.7em;
        margin-bottom: 0;
        font-style: italic;
    }

    &--with-detail {
        align-items: flex-start;

        .criterion__icon {
            margin-top: 0.125em;
        }
    }
    &--disabled,
    &--undefined {
        .criterion__icon {
            color: $gray-light;
        }
    }
}

// When inside a compact List (Fund Widget)
.criterionlist--compact {
    .criterion__title,
    .criterion__link {
        display: none;
    }
}
