.newsletter-form {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem auto;

    &--narrow {
        max-width: 690px;
    }

    &__content {
        flex-basis: 100%;
    }
    &__actions {
        flex-basis: 100%;
        text-align: center;
    }
    &__note {
        color: $gray-dark;
        font-style: italic;
        font-size: 0.875rem;
        text-align: left;

        a {
            text-decoration: underline;
            @include hover-focus {
                text-decoration: none;
            }
        }
    }

    @include mq(md) {
        flex-wrap: nowrap;

        &__content {
            flex: 1 0 250px;
        }
        &__actions {
            padding: 1rem 0 1rem 1rem;
            flex: 0 0 auto;
        }
    }
}
