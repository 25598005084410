// Home Page Styles

.home .section {
    &__logo {
        margin: 2rem auto 1rem;
    }
    &__intro {
        font-size: 1.125rem;
    }

    @include mq(md) {
        &__logo {
            width: 200px;
        }
        &__intro {
            max-width: 50ch;
            margin: 0 auto;
        }
    }
    @include mq(lg) {
        padding-top: $spacing-y * 1.5;
        padding-bottom: $spacing-y * 1.5;
    }
}

.home .demo {
    margin-top: 2rem;
    padding-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        bottom: 0;
        background-color: $brand-primary;
    }

    &__step:not(:last-child) {
        margin-bottom: $spacing-y * 2;

        @include mq(md) {
            margin-bottom: $spacing-y * 3;
        }
    }
    &__title {
        padding: 1rem 0;
        background-color: $body-bg;
    }
    &__text {
        font-size: 1.125rem;
        margin: 0 auto $spacing-y;
        padding-bottom: 1rem;
        max-width: 50ch;
        background-color: $body-bg;
    }
    &__image {
        @include card;
        max-width: 100%;
        cursor: not-allowed;
    }
}
