.accountnav {
    &__list {
        margin: 0 -1rem;
    }
    &__item {
        &:not(:last-child) {
            border-bottom: 1px solid rgba(#fff, 0.125);
        }
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 1rem;
        color: $gray-light;

        @include hover-focus {
            color: #fff;
        }

        .icon {
            margin-right: 0.25em;
        }

        &--active {
            color: $brand-cyan !important;
        }
    }
}
