.slider {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    * {
        box-sizing: inherit;
    }

    &__track {
        overflow: hidden;
    }

    &__slides {
        position: relative;
        width: 100%;
        list-style: none;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        touch-action: pan-Y;
        overflow: hidden;
        padding: 0;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        will-change: transform;

        &--dragging {
            user-select: none;
        }
    }

    &__slide {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.5rem 1rem;
        flex-shrink: 0;
        white-space: normal;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;

        a {
            user-select: none;
            -webkit-user-drag: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }
    }

    &__arrows {
        -webkit-touch-callout: none;
        user-select: none;
    }

    &__arrow {
        @include flex-center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 0;
        background-color: $gray;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s linear;

        @include hover-focus {
            background-color: $brand-primary;
        }

        &--left {
            left: -0.5rem;
        }
        &--right {
            right: -0.5rem;
        }
        &--disabled {
            background-color: $gray-light;
            pointer-events: none;
        }

        @include mq(lg) {
            &--left {
                left: -3rem;
            }
            &--right {
                right: -3rem;
            }
        }
    }

    &__bullets {
        display: none;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        padding: 1rem 0;
        -webkit-touch-callout: none;
        user-select: none;

        @include mq(md) {
            display: flex;
        }
    }

    &__bullet {
        display: block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 0;
        padding: 0;
        margin: 0 0.25rem;
        background-color: $gray-light;
        transition: background-color 0.2s linear;

        @include hover-focus {
            background-color: $gray;
        }

        &--active {
            background-color: $brand-primary !important;
        }
    }

    &--rtl {
        direction: rtl;
    }
}
