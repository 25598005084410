//--------------------
// SCSS VARIABLES
//--------------------

@import '~bootstrap/scss/_variables.scss';

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    md: 768px,
    lg: 992px,
    xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    md: 720px,
    lg: 960px,
    xl: 1140px
);

$grid-gutter-width: 2rem;

// Z-Index Stack
//
// Control the order of layers in the application
$z-layers: (
    'modal': 999,
    'messages': 100,
    'header': 75,
    'navigation': 50,
    'content-overlay': 25
);

// Colors
//
// Grayscale.

$gray-darkest: #121212;
$gray-darker: #323232;
$gray-dark: #6f6f6e;
$gray: #a0a0a0;
$gray-light: #c5c6c6;
$gray-lighter: #e6e6e6;
$gray-lightest: #f7f7f7;

// Brand Colors

$brand-blue-dark: #282737;
$brand-blue: #009eaa;
$brand-cyan: #50e3c2;

$brand-green: #259078;
$brand-yellow: #f5a623;
$brand-red: #f95368;

// State Colors

$state-success: $brand-green;
$state-info: $brand-blue;
$state-warning: $brand-yellow;
$state-error: $brand-red;

//Color Mappings

$brand-primary: $brand-blue;
$brand-primary-dark: darken($brand-blue, 6%);

// Links
//
// Style anchor elements.

$link-color: $brand-primary;
$link-decoration: none;
$link-hover-color: $brand-primary-dark;
$link-hover-decoration: none;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fbfbfb;
$body-color: $brand-blue-dark;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-system: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$font-family-brand: 'Montserrat', 'Roboto', 'Helvetica Neue', sans-serif !default;
$font-family-base: $font-family-system !default;

$font-size-root: 100%;
$font-size-base: 1rem;

$line-height: 1.625;
$leading: round(16 * $line-height);
$leading-rem: $leading / 16;

// Animation
//
// Default easing curves, Transitions, etc

$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
$animation-curve-default: $animation-curve-fast-out-slow-in;

// Misc Shared
//
// common shared styles

$border-radius: 0;
$spacing-x: 2rem;
$spacing-y: 2rem;

$box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
$box-shadow-elevated: 0 12px 35px 4px rgba(0, 0, 0, 0.1),
    0 10px 14px rgba(0, 0, 0, 0.05);

$brand-primary-gradient: linear-gradient(
    to right,
    rgba($brand-primary, 0) 10%,
    rgba($brand-primary, 1) 100%
);

// Components
//
// Variables related to specific elements

$header-height: 70px;
