// Alert Component

.alert {
    font-size: 1rem;
    position: relative;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    padding: 1rem;

    .icon {
        margin-right: 0.5rem;
    }

    a,
    .btn--link {
        text-decoration: underline;
        @include hover-focus {
            text-decoration: none;
        }
    }

    // Variations
    &--info {
        color: darken($state-info, 10%);
        background-color: lighten(desaturate($state-info, 58%), 55%);
        border-color: lighten(desaturate($state-info, 35%), 5%);

        a {
            color: darken($state-info, 10%);
        }
    }
    &--warning {
        color: darken($state-warning, 20%);
        border-color: lighten($state-warning, 25%);
        background-color: lighten($state-warning, 40%);
    }
    &--success {
        color: darken($state-success, 10%);
        border-color: lighten($state-success, 25%);
        background-color: lighten($state-success, 50%);
    }
    &--error {
        color: darken($state-error, 22%);
        border-color: lighten($state-error, 20%);
        background-color: desaturate(lighten($state-error, 31%), 25%);
    }
}
