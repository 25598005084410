// Form Component

form {
    .req {
        position: relative;
        top: -0.125em;
        line-height: 0.5em;
        padding-left: 0.125em;
        color: $state-error;
    }
}

.form {
    &--narrow {
        max-width: 840px;
    }

    &__message {
        margin-bottom: 1rem;
    }

    &__actions {
        padding-top: 1rem;

        &--flex {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > * + * {
                margin-left: $spacing-x;
            }
        }
    }
}

.formwidget {
    width: 100%;
    background-color: #fff;
    box-shadow: $box-shadow-elevated;
    max-width: #{map-get($container-max-widths, 'lg')};
    padding: 1rem;
    margin: 2rem auto;

    &--small {
        max-width: 480px;
        text-align: center;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}
