//
// Utility Classes
//

.u-clearfix {
    @include clearfix;
}
.u-p0 {
    padding: 0 !important;
}
.u-mb0 {
    margin-bottom: 0 !important;
}
.u-mt0 {
    margin-top: 0 !important;
}
.u-mb1 {
    margin-bottom: 1rem !important;
}
.u-mt1 {
    margin-top: 1rem !important;
}
.u-mb2 {
    margin-bottom: 2rem !important;
}
.u-mt2 {
    margin-top: 2rem !important;
}
.u-mt3 {
    margin-top: 3rem !important;
}
.u-mt4 {
    margin-top: 4rem !important;
}
.u-align-left {
    text-align: left !important;
}
.u-align-center {
    text-align: center !important;
}
.u-align-right {
    text-align: right !important;
}
.u-text-wrap {
    white-space: normal !important;
}
.u-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.u-color-gray {
    color: $gray !important;
}
.u-truncate {
    @include truncate;
}
.u-mobile-only {
    @include mq(md) {
        display: none;
    }
}

// Text Utilities

.u-alignleft {
    text-align: left !important;
}
.u-aligncenter {
    text-align: center !important;
}
.u-alignright {
    text-align: right !important;
}

//
// Screenreaders
//

.sr-only {
    @include sr-only();
}

.sr-only-focusable {
    @include sr-only-focusable();
}

.sr-skip-link {
    @include sr-only();
    @include sr-only-focusable();
    text-decoration: none;

    &:focus {
        position: absolute;
        z-index: 9999;
        left: 50%;
        top: 0;
        font-size: 1rem;
        transform: translateX(-50%);
        background-color: $gray-darkest;
        color: #fff;
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 1rem 1.5rem;
        outline: 0;
        white-space: nowrap;
    }
}
