//--------------------
// SCSS MIXINS
//--------------------

//Bootstrap Mixins
@import '~bootstrap/scss/_mixins.scss';

// shorthand for media-breakpoint-up
@mixin mq($name) {
    @include media-breakpoint-up($name) {
        @content;
    }
}

// Custom MQ for max-widths.
// applies to all widths below the given breakpoint
// i.e. mq-down(md, (md: 768px)) = max-width: 767px
@mixin mq-down($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (max-width: ($min - 1px)) {
            @content;
        }
    } @else {
        @content;
    }
}

// Text Hyphenation
@mixin hyphenate() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

// Text Truncation
@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin coverall() {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin scrollable() {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@mixin list-reset() {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@mixin link-reset() {
    color: inherit !important;
    text-decoration: none;
}

@mixin button-reset() {
    border: 0;
    padding: 0;
    background-color: transparent;
}

@mixin padding-x() {
    padding-left: 1rem;
    padding-right: 1rem;

    @include mq(md) {
        padding-left: $spacing-x;
        padding-right: $spacing-x;
    }
}

@mixin card() {
    background-color: #fff;
    box-shadow: $box-shadow;
}
@mixin card-hover() {
    position: relative;
    transform: translateY(0);
    transition: transform 0.2s ease;

    //pre-render box shadow for better performance
    &::after {
        @include coverall;
        content: '';
        display: block;
        z-index: -1;
        box-shadow: $box-shadow-elevated;
        transition: opacity 0.2s ease;
        opacity: 0;
    }
    &:hover {
        transform: translateY(-3px);

        &::after {
            opacity: 1;
        }
    }
}

// Helper to generate value-based colors (Ampelfarben)
// example usage: @include traffic-light-states('.criterion', color);
@mixin traffic-light-states($block, $property) {
    #{$block}--low & {
        #{$property}: $brand-red;
    }
    #{$block}--medium & {
        #{$property}: $brand-yellow;
    }
    #{$block}--high & {
        #{$property}: $brand-green;
    }
    #{$block}--disabled &,
    #{$block}--undefined & {
        #{$property}: $gray-light;
    }
}
@mixin traffic-light-states-inverted($block, $property) {
    #{$block}--low & {
        #{$property}: $brand-green;
    }
    #{$block}--medium & {
        #{$property}: $brand-yellow;
    }
    #{$block}--high & {
        #{$property}: $brand-red;
    }
    #{$block}--disabled &,
    #{$block}--undefined & {
        #{$property}: $gray-light;
    }
}
